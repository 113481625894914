<script>
    import authService from "@/service/authService";
    import userInfoService from "@/service/userInfoService";
    import Footer from "@/components/footer";
    import firebase from "firebase/app";
    import "firebase/firestore";
    import "firebase/database";

    export default {
        components: {
          Footer
        },
        data() {
          return {
            
          }
        },
        methods: {
          // 微软登录
          microsoftSignin() {          
            authService.micrsoftSignin().then((userInfo) => {
              userInfoService.getUserInfoByDocId(userInfo.uid).then(res => {
                if(res == null) {
                  const data = {
                    uid: userInfo.uid,
                    email: userInfo.email,
                    displayName: userInfo.displayName,
                    photoURL: userInfo.photoURL,
                    providerId: userInfo.providerId,
                    deleteFlag: 0,
                    createDateTime: firebase.database.ServerValue.TIMESTAMP,
                    updateDateTime: firebase.database.ServerValue.TIMESTAMP,
                    note: "",
                  };
                  // 将用户信息插入Firestore
                  userInfoService.insertUserInfoToFirestore(data).then((res) => {
                    // ok
                  })
                }
                this.$store.dispatch("userInfo", userInfo);
                this.$router.push("/boards");
              })
            })
          },
          // 谷歌登录
          googleSignin() {
            authService.googleSignin().then((userInfo) => {
              userInfoService.getUserInfoByDocId(userInfo.uid).then(res => {
                if(res == null) {
                  const data = {
                    uid: userInfo.uid,
                    email: userInfo.email,
                    displayName: userInfo.displayName,
                    photoURL: userInfo.photoURL,
                    providerId: userInfo.providerId,
                    deleteFlag: 0,
                    createDateTime: firebase.database.ServerValue.TIMESTAMP,
                    updateDateTime: firebase.database.ServerValue.TIMESTAMP,
                    note: "",
                  };
                  // 将用户信息插入Firestore
                  userInfoService.insertUserInfoToFirestore(data).then((res) => {
                    // ok
                  })
                }
                this.$store.dispatch("userInfo", userInfo);
                this.$router.push("/boards");
              })
            });
          },
          // GitHub登录
          githubSignin() {
            authService.githubSignin().then((userInfo) => {
              console.log(userInfo)
            })
          }
        },
        mounted() {
            document.querySelectorAll("form .auth-pass-inputgroup").forEach(function(item) {
              item.querySelectorAll(".password-addon").forEach(function(subitem) {
                subitem.addEventListener("click", function() {
                  var passwordInput = item.querySelector("input");
                  if (passwordInput.type === "password") {
                    passwordInput.type = "text";
                  } else {
                    passwordInput.type = "password";
                  }
                });
              });
            });
        },
      };
</script>
<style scoped>
  [type="button"] img {
    position: absolute;
    left: 5%;
  }
</style>
<template>
  <div class="auth-page-wrapper">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
      <Particles id="tsparticles" :options="{
            particles: {
              number: {
                value: 90,
                density: {
                  enable: true,
                  value_area: 800,
                },
              },
              color: {
                value: '#ffffff',
              },
              shape: {
                type: 'circle',
                stroke: {
                  width: 0,
                  color: '#000000',
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  src: 'img/github.svg',
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.8,
                random: true,
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0,
                  sync: false,
                },
              },
              size: {
                value: 4,
                random: true,
                anim: {
                  enable: false,
                  speed: 4,
                  size_min: 0.2,
                  sync: false,
                },
              },
              line_linked: {
                enable: false,
                distance: 150,
                color: '#ffffff',
                opacity: 0.4,
                width: 1,
              },
              move: {
                enable: true,
                speed: 2,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'out',
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              detect_on: 'canvas',
              events: {
                onhover: {
                  enable: true,
                  mode: 'bubble',
                },
                onclick: {
                  enable: true,
                  mode: 'repulse',
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 4,
                  duration: 2,
                  opacity: 0.8,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
            config_demo: {
              hide_card: false,
              background_color: '#b61924',
              background_image: '',
              background_position: '50% 50%',
              background_repeat: 'no-repeat',
              background_size: 'cover',
            },
          }" />
    </div>

    <div class="auth-page-content">
      <b-container>
        <b-row>
          <b-col lg="12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo-light.png" alt="" height="20">
                </router-link>
              </div>
              <p class="mt-3 fs-15 fw-semibold">Premium Admin & Dashboard Template</p>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-center">
          <b-col md="8" lg="6" xl="5">
            <b-card no-body class="mt-4">

              <b-card-body class="p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to Otukare.</p>
                </div>
                <div class="p-2 mt-4">
                  <div class="text-center">
                    <div class="signin-other-title">
                      <h5 v-if="this.$route.path == '/signin'" class="fs-14 mb-4 title text-muted">{{ $t('signin-with') }}</h5>
                      <h5 v-if="this.$route.path == '/signup'" class="fs-14 mb-4 title text-muted">{{ $t('signup-with') }}</h5>
                    </div>
                    <b-button variant="light" class="bg-gradient waves-effect waves-light w-100" @click="googleSignin()">
                      <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.401/static/media/google-logo.e086107b.svg"
                      height="20" alt="google">
                      <span class="fw-bold">Google で続行</span>
                    </b-button>
                  </div>
                  <div class="mt-2 text-center">
                    <b-button variant="light" class="bg-gradient waves-effect waves-light w-100" @click="microsoftSignin()">
                      <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.398/static/media/microsoft-logo.42b61fa1.svg" 
                        height="20" alt="Microsoft">
                      <span class="fw-bold">Microsoft で続行</span>
                    </b-button>
                  </div>
                  <div class="mt-2 text-center">
                    <b-button variant="light" class="bg-gradient waves-effect waves-light w-100" @click="githubSignin()">
                      <img src="https://cdn.qiita.com/assets/brand_icons/icon-github-64b4126624f0fd41c312d291471c08b1.svg" 
                        height="20" alt="GitHub">
                      <span class="fw-bold">GitHub で続行</span>
                    </b-button>
                  </div>
                </div>
              </b-card-body>
            </b-card>

            <!-- <div class="mt-4 text-center">
              <router-link to="/auth/reset-pwd-basic" class="fw-bold text-primary text-decoration-underline">
                Forgot password?
              </router-link>
              <p class="mb-0 mt-3">Don't have an account ?
                <router-link to="/signup" class="fw-bold text-primary text-decoration-underline">Signup</router-link>
              </p>
            </div> -->

          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer />
  </div>
</template>
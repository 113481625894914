import firebase from "firebase/app";
import "firebase/auth";
import UserInfo from "@/form/userInfo";
import StringUtils from "@/util/stringUtils";

export default {

    // send email verification to user mail
    sendEmailVerification: () => {

        var actionCodeSettings = {
            url: 'http://localhost:8080',
            subject: "Message titlsssssssssssssse",
            html: "<h1>HTML version of the message</h1>"
        };

        return new Promise((resolve, reject) => {
            firebase.auth().currentUser.sendEmailVerification(actionCodeSettings).then(() => {
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // login by email and password
    loginByEmailAndPassword: (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {
                let userInfo = new UserInfo(
                    result.user.uid,
                    result.user.displayName,
                    result.user.email,
                    result.user.phoneNumber,
                    result.user.photoURL,
                    result.user.providerId,
                    Boolean(result)
                )
                resolve(userInfo)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    // register by email and password
    registerByEmailAndPassword: (email, password) => {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password).then((result) => {

                // https://avatars.dicebear.com/api/adventurer-neutral/ndsesign.co.uk.svg
                // https://eu.ui-avatars.com/api/?name=y&size=250
                let photoURL = "";
                if (StringUtils.isEmpty(result.user.photoURL)) {
                    // 使用邮箱密码注册时没有默认头像URL需要为该用户准备一个头像URL
                    photoURL = "https://avatars.dicebear.com/api/adventurer-neutral/" + email[0] + email[1] + ".co.uk.svg";
                }

                let userInfo = new UserInfo(
                    result.user.uid,
                    result.user.email, // 使用账号密码登录时，用户的默认名字时mail
                    result.user.email,
                    result.user.phoneNumber,
                    photoURL,
                    result.user.providerId,
                    Boolean(result)
                )
                resolve(userInfo)
            }).catch((error) => {
                reject(error)
            });
        })
    },
    // signin use google 
    googleSignin: () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithPopup(provider).then((result) => {
                console.log('google result', result)
                let userInfo = new UserInfo(
                    result.user.uid,
                    result.user.displayName,
                    result.user.email,
                    result.user.phoneNumber,
                    result.user.photoURL,
                    result.user.providerId,
                    Boolean(result)
                )
                resolve(userInfo)
            }).catch(error => {
                // TODO ERROR
                reject(error)
            })
        })
    },
    // signin use mircsoft
    micrsoftSignin: () => {
        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithPopup(provider).then((result) => {
                console.log('micrsoft result', result)
                const photoURL = 'https://eu.ui-avatars.com/api/?name=' + result.user.email[0] + '&size=250';
                let userInfo = new UserInfo(
                    result.user.uid,
                    result.user.displayName,
                    result.user.email,
                    result.user.phoneNumber,
                    photoURL,
                    result.user.providerId,
                    Boolean(result)
                )
                resolve(userInfo)
            }).catch(error => {
                // TODO ERROR
                reject(error)
            })
        })
    },
    // signin use github
    githubSignin: () => {
        const provider = new firebase.auth.GithubAuthProvider();
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithPopup(provider).then((result) => {
                console.log('github result', result)
                let userInfo = new UserInfo(
                    result?.user?.uid,
                    result?.user?.additionalUserInfo?.username,
                    result?.user?.email,
                    result?.user?.phoneNumber,
                    result?.user?.photoURL,
                    result?.user?.additionalUserInfo?.providerId,
                    Boolean(result)
                )
                resolve(userInfo)
            }).catch(error => {
                // TODO ERROR
                reject(error)
            })
        })
    }
}
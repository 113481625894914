import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";

export default {
    // insert user info to firestore
    insertUserInfoToFirestore: (userInfo) => {
        return new Promise((resolve, reject) => {
            const collection = firebase.firestore().collection("userInfo").doc(userInfo.uid);
            collection.set(userInfo).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    // get user info by doc id
    getUserInfoByDocId: (docId) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().collection("userInfo").doc(docId)
            docRef.get().then(doc => {
                if (doc.exists) {
                    resolve(doc.data())
                } else {
                    resolve(null)
                }
            }).catch(error => {
                reject(error);
            })
        })
    }


}
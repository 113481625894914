<template>
  <footer class="footer">
    <b-container>
      <b-row>
        <b-col lg="12">
          <div class="text-center">
            <p class="mb-0 text-muted">
              Copyright ©{{ new Date().getFullYear() }} All rights reserved | otukare (お疲れ)
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>
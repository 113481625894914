export default class UserInfo {
    constructor(
        uid,
        displayName,
        email,
        phoneNumber,
        photoURL,
        providerId,
        isLogined
    ) {
        this.uid = uid;
        this.displayName = displayName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.photoURL = photoURL;
        this.providerId = providerId;
        this.isLogined = isLogined;
    }
}